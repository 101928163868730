import Axios from 'axios'
import store from './store'

const baseUrl = process.env.NODE_ENV === 'development' ? 'api/' : ('//' + window.location.host + '/api/')
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 请求拦截器
Axios.interceptors.request.use(function (config) {
  config.headers.Authorization = store.state.token || ''
  return config
}, function (error) {
  return Promise.reject(error)
})

Axios.interceptors.response.use((response) => {
  const code = response.data.code
  if (code === 200) {
    return response.data
  }
  alert(response.data.msg || '网络请求错误')
  return false
})

export default {
  post (url, data) {
    return Axios.post(baseUrl + url, data)
  },
  get (url, params) {
    return Axios.get(baseUrl + url, { params })
  },
  baseUrl
}
